<template>
  <div class="annualExamine">
    <div class="flex-box">
      <el-button type="success"
                 icon="el-icon-plus"
                 @click="addYearExamine">新增年度考核</el-button>
      <div class="query-box">
        <el-date-picker v-model="queryYear"
                        value-format="yyyy"
                        type="year"
                        placeholder="请选择年度">
        </el-date-picker>
        <el-select v-model="queryType"
                   clearable
                   placeholder="请选择类型">
          <el-option v-for="type in typeList"
                     :key="type.value"
                     :label="type.name"
                     :value="type.value"></el-option>
        </el-select>
        <el-button type="primary"
                   class="margin"
                   @click="loadYearsExamine">查 询</el-button>
      </div>
    </div>
    <el-table :data="examineTable"
              class="examineTable-box"
              ref="examineTable">
      <el-table-column label="年度"
                       width="80px"
                       align="center"
                       prop="nd"></el-table-column>
      <el-table-column label="名称"
                       align="center"
                       prop="name"></el-table-column>
      <el-table-column label="类型"
                       align="center"
                       prop="typeStr"></el-table-column>
      <el-table-column label="开始时间-结束时间"
                       align="center">
        <template slot-scope="scope">
          <span>
            {{scope.row.starttime}}--{{scope.row.endtime}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="发布人"
                       align="center"
                       prop="opername"></el-table-column>
      <el-table-column label="状态"
                       width="100px"
                       align="center">
        <template slot-scope="scope">
          <el-tag :type="tagColor(scope.row.status)">{{scope.row.statusStr}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作"
                       align="center"
                       width="400px">
        <template slot-scope="scope">
          <!-- 评分结束不能编辑，评分中，评分结束不能发布和删除 -->
          <el-button type="primary"
                     :disabled="scope.row.status===2"
                     size="mini"
                     @click="modifyExamineForm(scope.row)">编 辑</el-button>
          <el-button type="success"
                     size="mini"
                     :disabled="scope.row.status===1 || scope.row.status===2"
                     @click="publishExamine(scope.row)">发 布</el-button>
          <el-button size="mini"
                     :disabled="scope.row.status===3 || scope.row.status===2"
                     :type="scope.row.status===1||scope.row.status===2?'info':'warning'"
                     @click="openExamineChange(scope.row.id,scope.row.status)">{{scope.row.status===0||scope.row.status===3?'开启考核':'关闭考核'}}</el-button>
          <el-button size="mini"
                     @click="viewExamine(scope.row)">查 看</el-button>
          <el-button type="danger"
                     size="mini"
                     :disabled=" scope.row.status===1"
                     @click="deleteExamineForm(scope.$index,scope.row)">删 除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="examineForm.id?'修改年度考核':'新增年度考核'"
               width="600px"
               :visible.sync="examineFormVisible">
      <el-form :model="examineForm"
               ref="examineForm"
               :rules="examineFormRules"
               class="examineForm-box"
               label-width="80px">
        <el-form-item label="年度"
                      prop="year">
          <el-date-picker v-model="examineForm.year"
                          value-format="yyyy"
                          type="year"
                          placeholder="请选择年度">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="名称"
                      prop="name">
          <el-input v-model="examineForm.name"
                    class="input-box"></el-input>
        </el-form-item>
        <el-form-item label="类型"
                      prop="type">
          <el-select v-model="examineForm.type"
                     :disabled="examineForm.id!==''"
                     placeholder="请选择类型">
            <el-option v-for="type in typeList"
                       :key="type.value"
                       :label="type.name"
                       :value="type.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间"
                      prop="startTime">
          <el-date-picker v-model="examineForm.startTime"
                          :picker-options="pickerOptions"
                          value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间"
                      prop="endTime">
          <el-date-picker v-model="examineForm.endTime"
                          :picker-options="pickerOptions"
                          value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="examineFormVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="addExamineConfirm">确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//问卷状态：发布成功：0，评分中：1，评分结束：2，未发布：3
export default {
  name: "annualExamine",
  data() {
    return {
      queryYear: "",
      queryType: "",
      examineTable: [],
      examineFormVisible: false,
      examineForm: {
        year: "",
        type: "",
        startTime: "",
        endTime: "",
        name: "",
        id: "",
      },
      currentExamine: null,
      examineFormRules: {
        year: [{ required: true, message: "请输入年度", trigger: "blur" }],
        type: [{ required: true, message: "请选择类型", trigger: "blur" }],
        startTime: [{ required: true, message: "请选择开始", trigger: "blur" }],
        endTime: [
          { required: true, message: "请选择结束时间", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入考核名称", trigger: "blur" }],
      },
      typeList: [
        { name: "业务评测", value: 0 },
        { name: "教师问卷", value: 1 },
        { name: "家长问卷", value: 2 },
      ],
      templateList: [],
      currentExamineType: "",
      currentExamineId: "",
      businessTemplateList: [],
      pickerOptions: {
        disabledDate(currentTime) {
          return currentTime.getTime() < new Date().getTime() - 86400000;
        },
      },
      openExamine: "",
      currentType: 1, //开启关闭考核
    };
  },
  created() {
    this.loadYearsExamine();
  },
  methods: {
    loadYearsExamine() {
      const that = this;
      let data = {};
      if (this.queryType !== "") {
        data.type = this.queryType;
      }
      // if (this.queryYear) {//查询年度
      //   data.ndid = this.queryYear;
      // }
      this.$post("/base/queryEvaluation.do", data)
        .then((res) => {
          that.examineTable = res.data;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    addYearExamine() {
      this.examineFormVisible = true;
      this.$nextTick(() => {
        this.$refs["examineForm"].resetFields();
        this.examineForm.id = "";
      });
    },
    modifyExamineForm(row) {
      this.examineFormVisible = true;
      this.currentExamine = row;
      this.$nextTick(() => {
        this.examineForm.year = row.nd;
        this.examineForm.name = row.name;
        this.examineForm.startTime = row.starttime;
        this.examineForm.endTime = row.endtime;
        this.examineForm.type = row.type;
        this.examineForm.id = row.id;
      });
    },
    addExamineConfirm() {
      let that = this;
      this.$refs["examineForm"].validate((valid) => {
        if (valid) {
          if (!this.examineForm.id) {
            for (let i = 0; i < this.examineTable.length; i++) {
              if (
                this.examineForm.year === this.examineTable[i].nd &&
                this.examineForm.type === this.examineTable[i].type
              ) {
                this.$message({
                  type: "warning",
                  message: "该年度已有问卷",
                });
                return;
              }
            }
            let newDate = new Date();
            let month = newDate.getMonth() + 1;
            if (month < 10) {
              month = `0${month}`;
            }
            let nowDay = `${newDate.getFullYear()}-${month}-${newDate.getDate()}`;
            if (this.examineForm.startTime === nowDay) {
              this.$message({
                type: "warning",
                message: "时间不能选为今日",
              });
              return;
            }
          } else {
            if (this.currentExamine.nd !== this.examineForm.year) {
              for (let i = 0; i < this.examineTable.length; i++) {
                if (
                  this.examineForm.year === this.examineTable[i].nd &&
                  this.examineForm.type === this.examineTable[i].type
                ) {
                  this.$message({
                    type: "warning",
                    message: "该年度已有问卷",
                  });
                  return;
                }
              }
            }
          }
          let data = {
            nd: this.examineForm.year,
            name: this.examineForm.name,
            starttime: this.examineForm.startTime,
            endtime: this.examineForm.endTime,
          };
          let apiUrl = "/base/insertEvaluationNd.do";
          if (this.examineForm.id) {
            data.id = this.examineForm.id;
            apiUrl = "/base/updateEvaluationNd.do";
          } else {
            data.type = this.examineForm.type;
          }
          this.$confirm("是否确认此操作", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$post(apiUrl, data)
                .then((res) => {
                  that.$message({
                    type: "success",
                    message: "操作成功!",
                  });
                  that.examineFormVisible = false;
                  that.loadYearsExamine();
                })
                .catch((err) => {
                  that.examineFormVisible = false;
                  that.$message({
                    type: "info",
                    message: err.message,
                    duration: 5000,
                  });
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            });
        } else {
          return false;
        }
      });
    },
    deleteExamineForm(index, row) {
      let that = this;
      this.$confirm("是否确认删除该问卷", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/deleteEvaluationNd.do", {
            ndid: row.id,
            type: row.type,
          })
            .then((res) => {
              that.$message({
                type: "success",
                message: "操作成功!",
              });
              that.examineTable.splice(index, 1);
            })
            .catch((err) => {
              that.$message.error(err.message);
            });
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    viewExamine(row) {
      let { id, yearid, type } = row;
      this.$router.push({
        name: "assessmentDetails",
        query: { id, yearid, type },
      });
    },
    publishExamine(row) {
      this.$router.push({
        name: "templateDetails",
        query: {
          currentExamineType: row.type,
          currentExamineId: row.id,
        },
      });
    },
    tagColor(status) {
      switch (status) {
        case 0:
          return "success";
        case 1:
          return "warning";
        case 2:
          return "info";
        case 3:
          return "";
      }
    },
    openExamineChange(ndid, status) {
      const that = this;
      let data = { ndid, type: this.currentType };
      if (status === 1) {
        this.currentType = 2;
        data.type = this.currentType;
      }
      if (status === 0) {
        this.currentType = 1;
        data.type = this.currentType;
      }
      this.$confirm("是否确定该操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/updateEvaluationNdStatus.do", data)
            .then((res) => {
              that.$message({
                type: "success",
                message: res.message,
              });
              that.loadYearsExamine();
            })
            .catch((err) => {
              that.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.flex-box {
  display: flex;
  justify-content: space-between;
}
.margin {
  margin: 0 5px;
}
.input-box {
  width: 217px;
}
.examineForm-box {
  padding-left: 110px;
}
.template-box {
  height: 500px;
  overflow-x: auto;
}
.padding-box {
  padding: 10px;
}
.query-box > div {
  margin-left: 5px;
}
</style>
